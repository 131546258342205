<template>
  <!-- 加入我们 - 岗位详情页面 -->
  <div class="join-detail-page bg-page">
    <div class="header bg-white shadow">
      <a-row class="w" type="flex" justify="space-between" align="middle">
        <a-col>
          <p class="fs-24 fc-title mb-10 fw-bold">{{ pageData.title }}</p>
          <p class="fc-content fs-16">发布时间 {{ pageData.createdDate && pageData.createdDate.split(' ')[0] }}</p>
        </a-col>
        <a-col><p class="fs-18 fc-content">
          <img style="width: 42px; height: 64px;" class="mr-10 mt-30" src="@/assets/join/eamil.png" alt="">
          <span>请投递简历至 {{ pageData.eamil }}</span>
        </p></a-col>
      </a-row>
    </div>
    <!-- 信息展示部分 -->
    <div class="info w bg-white shadow">
      <!-- <div class="base-info after-line">
        <p class="fc-title fs-20 mb-30 fw-bold">基础信息</p>
        <a-row type="flex">
          <a-col class="mb-20 fs-16" :span="6" v-for="(key, index) in baseDataKey" :key="index">
            <span class="fc-content">{{ baseDataLabel[index] }}：</span>
            <span class="fc-title">{{ pageData[key] || '' }}</span>
          </a-col>
        </a-row>
      </div> -->
      <div class="desc-info after-line">
        <p class="fc-title fs-20 mb-30 fw-bold">岗位描述</p>
        <p class="text fs-16 fc-content line-break">{{ pageData.jobDesc }}</p>
      </div>
      <div class="skill-info">
        <p class="fc-title fs-20 mb-30 fw-bold">岗位要求</p>
        <p class="text fc-content fs-16 line-break">{{ pageData.jobSkill }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Col } from 'ant-design-vue'
import api from '@/api'
export default {
  components: {
    'a-row': Row,
    'a-col': Col
  },
  data() {
    return {
      pageData: {},  // 页面数据
      baseDataKey: ['jobSite', 'number', 'classes','experience', 'education', 'department', 'salary'],
      baseDataLabel: ['工作地点','招聘人数','职位类别','工作年限','学历要求','所属部门','薪酬范围']      
    }
  },
  async created() {
    const { channelId, contentId } = this.$route.query
    if(!channelId || !contentId) return

    if(!localStorage.getItem('jdata')) {
      const { message, contents } = await api.getContentsList({ channelId: channelId })
      let allData = contents.filter(item => item.state === '已审核')  // 存储全部已审核数据
      let jdata = {}
      allData.forEach(ele => {
        jdata[ele.id] = ele
      })
      localStorage.setItem('jdata', JSON.stringify(jdata))
    }
    this.getPageData({channelId, contentId})
  },
  methods: {
    // 调接口 - 获取招聘详情数据
    async getPageData(query) {
      let data = JSON.parse(localStorage.getItem('jdata'))
      this.pageData = data[query.contentId]
      // const res = await api.getContent(query)
      // this.pageData = res || {}
    }
  }
}
</script>

<style lang="less" scoped>
@import url('~@/styles/m.less');

.join-detail-page{
  min-height: 100vh;
  padding: 64px 0 50px;
  .header{
    .w{  height: 120px; }
  }

  .info{
    margin-top: 20px; 
    padding: 0 35px 40px;
    &>div{ padding: 40px 0; }
    .base-info{
      padding-bottom: 30px;
    }
    .text{
      line-height: 34px;
    }
  }

  .after-line{
   border-bottom: 2px solid #e6e6e6; 
  }
}
</style>